import React, { useState } from 'react';
import JoinWaitlistMailChimp from './JoinNewsletterMailChimp';
import SuccessModal from './successModal';

interface WaitListModalProps {
  show: boolean;
  show2: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setShow2: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewsletterModal: React.FC<WaitListModalProps> = ({
  show,
  setShow,
  show2,
  setShow2,
}) => {
  const [message, setMessage] = useState('');

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
            <button
              className="absolute top-4 right-4 text-gray-600"
              onClick={() => setShow(false)}
            >
              &times;
            </button>
            <JoinWaitlistMailChimp
              setShow={setShow}
              setShow2={setShow2}
              setMessage={setMessage}
            />
          </div>
        </div>
      )}
      {show2 && (
        <SuccessModal setShow={setShow} setShow2={setShow2} message={message} />
      )}
    </>
  );
};

export default NewsletterModal;
