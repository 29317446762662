import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import SuccessModal from './successModal';
import { countries } from '../../data';

interface WaitlistFormProps {
  status: string;
  message: string;
  onValidated: (formData: any) => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setShow2: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewsletterForm: React.FC<WaitlistFormProps> = ({
  status,
  message,
  onValidated,
  setShow,
  setShow2,
}) => {
  const [email, setEmail] = useState('');

  const [error, setError] = useState('');

  const formValidator = () => {
    // dynamic form validation for all fields with dynamic error message
    const fields = [{ value: email, message: 'Please enter a valid email' }];

    for (const field of fields) {
      if (!field.value) {
        setError(field.message);
        return false;
      }
    }

    // Regex validation for email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email');
      return false;
    }

    setError('');
    return true;
  };

  const handleFormSubmit = () => {
    if (formValidator()) {
      onValidated({
        EMAIL: email,

        // AGREEMENT: agreement,
      });
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <img src="/asset/images/blueLogo.svg" alt="logo" />
        <button onClick={() => setShow(false)}>
          <IoMdClose
            style={{
              fontSize: '1.8rem',
              color: '#4B5563',
              cursor: 'pointer',
            }}
          />
        </button>
      </div>

      <h2 className="formHeader">Subcribe to our Newsletter</h2>
      <p className="text-sm text-gray-600 text-center mb-6 max-w-[289px] mx-auto">
        Subscribe to our newsletter to get updates on our latest products and
        offers.
      </p>

      {/* Form Fields */}

      <input
        type="email"
        placeholder="Enter your full email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full p-3 rounded-md bg-[#F7F7F7]"
        style={{
          outline: 'none',
        }}
      />

      {/* Error Message */}
      {error && <span className="text-red-500 text-sm">{error}</span>}

      {/* Submit Button */}
      <button
        onClick={handleFormSubmit}
        className="w-full bg-blue-500 text-white py-3 rounded-md font-bold"
      >
        Subscribe
      </button>

      {status === 'sending' && (
        <div className="text-blue-500 animate-pulse">Sending...</div>
      )}
      {status === 'error' && (
        <div className="text-red-500 animate-bounce">{message}</div>
      )}
      {status === 'success' && (
        <>
          <SuccessModal setShow={setShow} setShow2={setShow2} />
          {setShow(false)}
          {setShow2(true)}
        </>
      )}
    </div>
  );
};

export default NewsletterForm;
