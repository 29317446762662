import React from 'react';
const PageLoader = () => {
  return (
    <div className="h-svh w-full flex justify-center items-center bg-white">
      <div className="flex items-center space-x-2 relative py-6 animate-pulse">
        <img
          src="/asset/images/blueLogo.svg"
          className="w-[252.29px] h-[152.91px]"
          alt="logo"
        />
      </div>
    </div>
  );
};
export default PageLoader;
