import React, { useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import WaitlistForm from './WaitlistForm';

interface JoinWaitlistMailChimpProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setShow2: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const JoinWaitlistMailChimp: React.FC<JoinWaitlistMailChimpProps> = ({
  setShow,
  setShow2,
  setMessage,
}) => {
  const MAILCHIMP_URL =
    'https://lightforth.us18.list-manage.com/subscribe/post?u=e5f832f0147d6c7afd7ac0585&amp;id=6d5a78d0e2&amp;f_id=0093c3e1f0';

  return (
    <MailchimpSubscribe
      url={MAILCHIMP_URL}
      render={(props: any) => {
        const { subscribe, status, message } = props || {};

        if (status === 'success' && message) {
          setMessage(message);
        }

        return (
          <WaitlistForm
            status={status}
            message={message}
            onValidated={(formData: any) => subscribe(formData)}
            setShow={setShow}
            setShow2={setShow2}
          />
        );
      }}
    />
  );
};

export default JoinWaitlistMailChimp;
