import React, { Suspense } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Footer from './components/footer';
import Navbar from './components/navbar';
import ScrollToTop from './components/scrollToTop';
import PageLoader from './components/pageLoader';

function App() {
  // useEffect(() => {
  //   initializeAnalytics('G-QDZBE2ZL6W');
  //   trackPageView(window.location.pathname + window.location.search);
  // }, []);

  // usePageTracking();
  const Homepage = React.lazy(() => import('./pages/homepage/homepage'));
  const LightInterview = React.lazy(
    () => import('./pages/light-interview/lightInterview')
  );
  const LightChat = React.lazy(() => import('./pages/light-chat/lightChat'));
  const FrequentlyAsked = React.lazy(
    () => import('./pages/frequentlyAsked/frequentlyAsked')
  );
  const GetInTouch = React.lazy(() => import('./pages/getInTouch/getInTouch'));
  const Page404 = React.lazy(() => import('./pages/404'));
  const Blogs = React.lazy(() => import('./pages/blogs/blogs'));
  const BlogInformation = React.lazy(
    () => import('./pages/blogInformation/blogInformation')
  );
  const AboutUs = React.lazy(() => import('./pages/about/about'));
  const Career = React.lazy(() => import('./pages/career/career'));
  const Pricing = React.lazy(() => import('./pages/pricing/pricing'));
  const Resumebuilder = React.lazy(
    () => import('./pages/resumebuilder/resumebuilder')
  );
  const AutoApply = React.lazy(() => import('./pages/apply/apply'));
  const MainPage = React.lazy(() => import('./pages/Terms/service'));
  const Privacy = React.lazy(() => import('./pages/Terms/privacy'));
  const Policy = React.lazy(() => import('./pages/Terms/policy'));
  const Cookie = React.lazy(() => import('./pages/Terms/cookie'));
  return (
    <Suspense fallback={<PageLoader />}>
      <div className="App">
        <Navbar />
        <div className="pt-[100px]">
          <Routes>
            <Route element={<ScrollToTop />} />
            <Route path="/" element={<Homepage />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/auto-apply" element={<AutoApply />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs-information" element={<BlogInformation />} />
            <Route path="/career" element={<Career />} />
            <Route path="/faq" element={<FrequentlyAsked />} />
            <Route path="/get-in-touch" element={<GetInTouch />} />
            <Route path="/light-chat" element={<LightChat />} />
            <Route path="/light-interview" element={<LightInterview />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/resume" element={<Resumebuilder />} />
            <Route path="/terms-condition" element={<MainPage />} />
            <Route path="/privacy-statement" element={<Privacy />} />
            <Route path="/privacy-policy" element={<Policy />} />
            <Route path="/cookie" element={<Cookie />} />
            <Route path="/*" element={<Page404 />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Suspense>
  );
}

export default App;
