import React from 'react';
import { useState } from 'react';
import { color } from '../data';
const Annoucement = () => {
  const [open, setOpen] = useState(true);
  return (
    <>
      {open && (
        <div
          style={{
            position: 'relative',
            backgroundColor: color.lightBlue,
            height: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '.8rem',
            fontWeight: '500',
          }}
        >
          <p style={{ color: color.primary }}>
            📣 Lightforth AI is out now. Practice your first interview for free
            🥳
          </p>
          <button
            style={{
              position: 'absolute',
              right: '1rem',
              background: 'transparent',
              border: 0,
              color: '#B0AEAD',
              fontSize: '1rem',
              cursor: 'pointer',
            }}
            onClick={() => setOpen(false)}
          >
            X
          </button>
        </div>
      )}
    </>
  );
};

export default Annoucement;
